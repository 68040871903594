<!-- 核销页面 -->
<template>
	<div class="back_btn">
		<div style="padding-top:10px;padding-left:25px">
			<img style="width: 14px; height: 26px;" :src="exitImg" alt="" @click="exitBtn" class="exitImgCss" />
		</div>
		<div class="commonPannel pannelTop">
			<div class="btn1_1">选择相关活动</div>
			<div class="btn1_2">Select relevant activities</div>
			<div class="selectBox">
				<el-select v-model="activityName" filterable allow-create default-first-option :fit-input-width="true"
					placeholder="请选择活动" class="selectBtn" @change="censusActivityPeopleState"
					style="width:100%;margin-top:20px">
					<el-option v-for="item in activityList" :key="item.activityId" :label="item.activityName"
						:value="item.activityName" style="width:79vw">
						<div placement="top" :content="item.activityName" v-if="item.activityName.length > 25">
							<span>{{
								item.activityName.slice(0, 25) + "..."
							}}</span>
						</div>
						<span v-else>{{ item.activityName }}</span>
					</el-option>
				</el-select>
			</div>
			<van-divider :style="{ color: '#1686FC', borderColor: '#1686FC', padding: '0 90px' }" />
			<div class="nameBtn">
				<div class="activityNameLabel">活动名称:&nbsp;</div>
				<div class="activityName">{{ activityName }}</div>
			</div>
		</div>
		<div class="back_btn2">
			<el-button class="hx_btn" type="primary" @click="smhxBtn">
				<div style="display: flex; justify-content: center">
					<img :src="smImg" alt="" class="smImgCss" />
					<span>&nbsp;扫码核销</span>
				</div>
			</el-button>
		</div>
		<div class="commonPannel hexiao">
			<p style="font-weight:bold">未核销人数:</p>
			<span> {{ num }}</span>
		</div>
		<div class="commonPannel hexiao">
			<p style="font-weight:bold">已核销人数: </p>
			<span>{{ totalUseNum }}</span>
		</div>
		<div class="bottomBtn">
			<el-button class="yhxRecords" type="primary" @click="yhxBtn()">已核销记录</el-button>
		</div>
	</div>
</template>

<script>
export default {
	data() {
		return {
			exitImg: require("../../assets/sign/exit.png"),
			smImg: require("../../assets/sign/sm.png"),
			//当天活动列表
			activityList: [],
			activityId: this.getSession("activity")
				? this.getSession("activity").activityId
				: "",
			activityName: this.getSession("activity")
				? this.getSession("activity").activityName
				: "",
			totalUseNum: "",
			num: "",
		};
	},
	created() {
		if (this.getSession("activity")) {
			this.instance
				.censusActivityPeopleState({
					activityId: this.getSession("activity").activityId,
				})
				.then((res) => {
					this.totalUseNum = res.data.data.totalUseNum;
					this.num =
						parseInt(res.data.data.totalNoForgoNum) -
						parseInt(res.data.data.totalUseNum);
				});
		}
	},
	mounted() {
		this.getActivityList();
	},
	methods: {
		// 返回上一页
		exitBtn() {
			this.$router.back()
		},
		//不分页查询活动列表
		getActivityList() {
			console.log(this.formatStartTime(), "nowStartTime");
			console.log(this.formatEndTime(), "nowEndTime");
			this.instance
				.getActivityList({
					parameters: {
						activityStartTimeStart: this.formatStartTime(),
						activityStartTimeEnd: this.formatEndTime(),
					},
				})
				.then((res) => {
					console.log(res, "res");
					this.activityList = res.data.data;
					console.log(this.activityList, "activityList");
				});
		},

		//格式化开始时间
		formatStartTime() {
			const date = new Date();
			const year = date.getFullYear();
			const month = (date.getMonth() + 1).toString().padStart(2, "0");
			const day = date.getDate().toString().padStart(2, "0");
			const hours = date.getHours().toString().padStart(2, "0");
			const minutes = date.getMinutes().toString().padStart(2, "0");
			const seconds = date.getSeconds().toString().padStart(2, "0");
			return `${year}-${month}-${day} 00:00:00`;
		},
		//格式化结束时间
		formatEndTime() {
			const date = new Date();
			const year = date.getFullYear();
			const month = (date.getMonth() + 1).toString().padStart(2, "0");
			const day = date.getDate().toString().padStart(2, "0");
			return `${year}-${month}-${day} 23:59:59`;
		},
		//统计项目报名人员各状态数量
		censusActivityPeopleState() {
			this.activityId = this.activityList.filter(
				(activity) => activity.activityName === this.activityName
			)[0].activityId;
			this.instance
				.censusActivityPeopleState({
					activityId: this.activityId,
				})
				.then((res) => {
					this.totalUseNum = res.data.data.totalUseNum;
					console.log(
						res.data.data.totalNoForgoNum,
						"res.data.data.totalNoForgoNum"
					);
					console.log(
						res.data.data.totalUseNum,
						"res.data.data.totalUseNum"
					);
					this.num =
						parseInt(res.data.data.totalNoForgoNum) -
						parseInt(res.data.data.totalUseNum);
				});
		},
		//扫码核销按钮
		async smhxBtn() {
			if (!this.activityId) {
				await this.$dialog.confirm({ message: "请先选择活动！！！" });
				return;
			}
			const storageKey = "activity";
			this.setSession(storageKey, { activityId: this.activityId, activityName: this.activityName });
			//已取消跳转
			this.$router.push({
				path: "/scanCode",
				query: { key: storageKey },
			});
		},
		//已核销记录
		async yhxBtn() {
			const storageKey = "activity";
			if (!this.activityId) {
				await this.$dialog.confirm({ message: "请先选择活动！！！" });
				return;
			}
			this.setSession(storageKey, { activityId: this.activityId, activityName: this.activityName });
			//已取消跳转
			this.$router.push({
				path: "/record",
				query: { key: storageKey },
			});
		},
	},
};
</script>

<style scoped>
.back_btn {
	padding-top: 10px;
	padding-bottom: 50px;
	background-color: #EFF1F5;
	height: auto;
	min-height: 100%;
}

.commonPannel {
	margin: 30px auto;
	padding: 25px;
	width: 94%;
	border-radius: 10px;
	overflow: hidden;
	background-color: #fff;
}

.hexiao {
	display: flex;
	justify-content: space-between;
	color: #333333;
	font-size: 18px;
	font-family: PingFang SC, PingFang SC;
}

.pannelTop {
	padding: 30px;
	display: flex;
	flex-direction: column;
	justify-content: space-around;
	min-height: 250px;
}

.bottomBtn {
	/* border: 1px solid #000; */
	height: 100px;
}

.yhxRecords {
	margin-top: 50px;
	margin-left: 35%;
	width: 30%;
	/* height: 50px; */
	background: linear-gradient(135deg, #66b2fd 0%, #1686fc 100%);
	box-shadow: 0px 6px 30px 1px rgba(22, 134, 252, 0.37);
	border-radius: 20px;
	opacity: 1;
}


/* .exitImgCss {
	width: 14px;
	height: 26px;
	margin-left: 16px;
} */

.back_btn1 {
	width: 94%;
	background: #ffffff;
}

.btn1_1 {
	text-align: center;
	font-size: 20px;
	font-family: PingFang SC, PingFang SC;
	font-weight: 800;
	color: #333333;
}

.btn1_2 {
	margin-top: 5px;
	margin-bottom: 5px;
	text-align: center;
	font-size: 14px;
	font-family: PingFang SC, PingFang SC;
	/* font-weight: 800; */
	color: #bfbfbf;
}

.selectBtn {
	font-size: 20px;
	font-family: PingFang SC, PingFang SC;
	font-weight: 400;
	color: #333333;
}

.el-select /deep/ .el-input__inner {
	background-color: rgb(239, 241, 245);
}

.line {
	width: 30%;
	height: 1px;
	margin: 20px auto;
	background-color: #1686FC;
}

.nameBtn {
	display: flex;
	font-size: 20px;
	color: #333333;
	font-family: PingFang SC, PingFang SC;
	font-weight: bold;
	color: #333333;
	background-color: #FFFFFF;

}

.activityNameLabel {}

.activityName {
	flex: 1;
	overflow: hidden;
	text-overflow: ellipsis;
	display: -webkit-box;
	-webkit-line-clamp: 2;
	/* number of lines to show */
	-webkit-box-orient: vertical;
}

.back_btn2 {
	width: 100%;
	margin: 40px auto 40px;
}

.hx_btn {
	position: relative;
	width: 84%;
	margin-left: 8%;
	height: 50px;
	font-size: 20px;
	font-family: PingFang SC, PingFang SC;
	font-weight: bold;
	background: linear-gradient(135deg, #66b2fd 0%, #1686fc 100%);
	box-shadow: 0px 6px 30px 1px rgba(22, 134, 252, 0.37);
	border-radius: 20px 20px 20px 20px;
	opacity: 1;
}

.smImgCss {
	width: 20px;
	height: 20px;
}

.btn1_4 {
	width: 100%;
	height: 100px;
}

.btn1_5 {
	width: 100%;
	height: 50px;
}


.back_btn3 {
	width: 100%;
	margin-top: 30%;
}
</style>